<template>
	<v-card flat color="transparent">
		<v-combobox label="Add tags" chips clearable multiple outlined :items="items" item-text="name" item-value="id" v-model="selected" @input="checkForNewTags">
		</v-combobox>
		<v-dialog v-model="dialog" :width="mwstylesettings.SmallDialogWidth">
			<v-card>
				<v-card-title>{{mwtr.AreYouSure}}</v-card-title>
				<v-card-text>{{mwtr.NewTagMessage}}</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="cancelLatest" color="red" dark>{{mwtr.CancelButton}}</v-btn>
					<v-btn @click="confirmLatest" color="primary">{{mwtr.SaveButton}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">

	export default {
		name: "AddATag", 
		props: {
			value: {},
			tags: {}, // an array of tags to select from 
		},
		data: () => {
			return {
				dialog: false,
				latest: "",
				selected: []
			}
		},
		computed: {
			items(){
				return Object.values( this.tags )
			}, 
			values(){
				return this.selected.filter(tag => typeof tag !== "string").map( tag => {
					return tag.id
				}) || []
			}
		},
		methods: {
			isNew(tag){
				return typeof tag == "string";
			},
			newTag(tag){
				const self = this;
				return self.$store.dispatch("tags/new", {name: tag } ).then( (id) => {
					var newTag = {id: id, name: tag }
					self.selected = self.selected.filter( a => a !== tag )
					self.selected.push(newTag)
					self.dialog = false

				})
			},
			checkForNewTags(tags){
				if( !tags ){ return; } 
				var latest = tags[tags.length-1]
				if( this.isNew(latest) ){
					this.latest = latest;
					this.dialog = true;
				}
			},
			confirmLatest(){
				this.newTag(this.latest)
			},
			cancelLatest(){
				var name = this.latest
				this.selected = this.selected.filter( a => a !== name )
				this.dialog = false;
			}
		},
		watch: {
			values(){
				this.$emit( "change", this.values )
			} 
		},
		mounted(){
			const self = this;
			self.value.forEach( tag => {
				this.selected.push( self.tags[tag] )
			})
		}
	}
// </script>"