<template>
	<v-sheet flat>	
		<v-row>		
			<v-col cols="12" sm="12">
				<GeneralForm :id="id" collection="goals" :data="goal">
					<template v-slot:formContent>
						<v-text-field :label="mwtr[fieldDefs.name.label]" v-model="goal.name"></v-text-field>
						<v-textarea :label="mwtr[fieldDefs.mouseover.label]" v-model="goal.mouseover"></v-textarea>
						<AddATag v-if="Object.keys(tags).length" :tags="tags" :value="goal.tags" @change="setTags"></AddATag>
						<v-select label="Status" v-model="goal.status" :items="statuses"></v-select>
					</template>
				</GeneralForm>
			</v-col>
		</v-row>
	</v-sheet>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import AddATag from "@/components/admin/panels/AddATag"
	import GeneralForm from "@/components/admin/forms/GeneralForm"

	export default {
		name: "EditGoal", 
		props: {
			id: {}
		},
		data: () => {
			return {
				statuses: ['pending', 'published', 'deleted']
			}
		},
		computed: {
			...Vuex.mapState( {
				goals: state => state.goals,
				tags: state => state.tags
			}),
			fieldDefs(){
				return this.mwsettings.fields.Goal
			},
			fields(){
				return this.mwsettings.getFields("Goal");
			},
			goal(){
				var goal = this.$store.state.goals[this.id]
				return goal || this.mwsettings.getEmpty("Goal");
			}
		},
		components: {
			AddATag,
			GeneralForm, 
		},
		methods: {
			setTags(tags){
				this.goal.tags = tags;
			}
		},
		created(){
			if( this.id ){			
				this.$store.dispatch("goals/fetchOne", this.id)
			}
			this.$store.dispatch("tags/fetchAll")
		},
		destroyed(){
			this.$store.dispatch("detachOne", this.id)
		},

	}
// </script>"