<template>
	<v-sheet flat>	
		<slot name="formContent"></slot>
		<p class="text-right">
			<v-btn color="primary" @click="save">{{mwtr.SaveButton}}</v-btn>
		</p>
		<Saved :trigger="saved" @close="saved = false"></Saved>
	</v-sheet>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">

	import Saved from "@/components/notices/Saved"
	export default {
		name: "Form", 
		props: {
			id: {}, 
			collection: {}, 
			data: {}, 
			pathName: {}
		},
		data: () => {
			return {
				saved: false,
			}
		},
		components: {
			Saved
		},
		computed: {
			isNew(){
				// to check if we are editing an existing org
				return !this.id; 
			}
		},
		methods: {
			update(){
				var self = this;
				self.$store.dispatch("updateDoc", {
					data: self.data, 
					doc: self.collection + "/" + self.id 
				}).then( () => {
					self.saved = true;
					self.$emit("saved")
				})
			},
			new(){
				const self = this;
				var pathName = this.pathName ? this.pathName : this.collection;
				self.$store.dispatch( self.collection + "/new", self.data ).then( id => {
					self.saved = true;
					var path = "/admin/" + pathName + "/" + id;
					self.$router.replace( path )
				})
			},
			save(){
				if( this.isNew ){
					this.new();
				}
				else{
					this.update()
				}
			}
		},
	}
// </script>"